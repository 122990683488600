import React from 'react';
import Footer from '../components/Footer';
import '../styles/about.scss';
import { Helmet } from 'react-helmet-async';

const About = () => {
  return (
    <>
      <Helmet>
        <title>Про нас</title>
        <meta name="keywords" content="кальян Київ, кальян Киев, кальян виноградарь, подол, теремки, покурити кальян, кальян Ірпінь, забронювати кальян, настольні ігри" />
        <link rel="canonical" href="/about" />
        <meta name="description" content="Lima Hookah переможець Hookah Battle Slavic Cup та “Кращий крафтовий кальян”" />
      </Helmet>
      <main className="about_wrapper">
        <div className="inner_wrapper_content">
          <div className="lima"></div>
          <div className="winner">
            <div className="winner_logo"></div>
            <div className="winner_text">
              <h2>
                Переможець Hookah Battle Slavic Cup <br /> або Битви Кальянщиків{' '}
                <br /> «Словʼянський Кубок» 2018
              </h2>
              <p>
                Переможці у номінації <br /> “Кращий крафтовий кальян”
              </p>
            </div>
          </div>

          <h2 className="battles_text">
            Учасник та призер низки кальянних
            <br /> батлів України
          </h2>
          <div className="battles_wrapper">
            <div className="battles">
              <div className="battles_item">
                <div className="battles_img"></div>
                <p>Всеукраинское Кальянное Облако</p>
              </div>
              <div className="battles_item">
                <div className="battles_img"></div>
                <p>
                  Poltava Hookah
                  <br /> Show
                </p>
              </div>
              <div className="battles_item">
                <div className="battles_img"></div>
                <p>Lords Of The Hookah</p>
              </div>
              <div className="battles_item">
                <div className="battles_img"></div>
                <p>Spring Hookah Fest</p>
              </div>
            </div>
          </div>

          <h3 className="advantages_text">У стінах нашого закладу зібрані:</h3>
          <div className="advantages_wrapper">
            <div className="advantages">
              <div className="advantage_item">
                <div className="advantage_img"></div>
                <p>Широкий парк кальянів</p>
              </div>
              <div className="advantage_item">
                <div className="advantage_img"></div>
                <p>Напої із різних точок світу</p>
              </div>
              <div className="advantage_item">
                <div className="advantage_img"></div>
                <p>Величезний асортимент тютюну</p>
              </div>
              <div className="advantage_item">
                <div className="advantage_img"></div>
                <p>Майстри своєї справи</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
      ;
    </>
  );
};

export default About;
