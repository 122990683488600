import React from 'react';
import Footer from '../components/Footer';
import '../styles/partners.scss'

const Partner = () => {
  return <main className="partner_wrapper">
      <div className="inner_wrapper_content">
        <div className="lima"/>
        <h2 className="title_center">
            Основные условия для<br /> открытия франшизы:
        </h2>
        <div className="conditions_wrapper">
          <div className="conditions">
            <div className="condition_item">
              <div className="cond_text"></div>
              <p>Год основания</p>
            </div>
            
            <div className="condition_item">
              <div className="cond_text"></div>
              <p>Стоимость франшизы (паушальный взнос)</p>
            </div>
            
            <div className="condition_item">
              <div className="cond_text"></div>
              <p>Роялти от оборота</p>
            </div>
            
            <div className="condition_item">
              <div className="cond_text"></div>
              <p>Срок окупаемости</p>
            </div>
            
            <div className="condition_item">
              <div className="cond_text"></div>
              <p>Сумма минимальных вложений:</p>
            </div>
            
            <div className="condition_item">
              <div className="cond_text"></div>
              <p>Минимальный размер торговой площади</p>
            </div>
            
          </div>
        </div>
        
        <h2 className="require_text">Требования  для открытия<br /> франшизы:</h2>
          <div className="require">
            <div className="require_left">
                <p>•	Понимание кальянного дела;</p>
                <p>•	Желание развиваться, учиться, совершенствовать мастерство;</p>
                <p>•	Помещение (аренда/собственность) площадью минимум 100 квадратных метров. Место расположения здания (помещения) – идеально: спальные районы, подвальные, полуподвальные помещения, не в жилом доме, приоритетно в отдельно стоящем 
                    здании;</p>
            </div>
            <div className="require_right">
                <p>•	Наличие санузла;</p>
                <p>•	Наличие приточно-вытяжной вентиляции;</p>
                <p>•	Получение паспорта помещения  по окончании ремонтных работ;</p>
                <p>•	Обязательное соблюдение стандартов работы заведения.</p>
            </div>
          </div>

          <h2 className="require_text">Поддержка при открытии<br /> заведения:</h2>
          <div className="require">
            <div className="require_left">
                <p>•	Консультация в поиске помещения;</p>
                <p>•	Консультации в процессе ремонтных работ;</p>
                <p>•	Сопровождение по оформлению учредительных документов, лицензий;</p>
                <p>•	Обучение кальянному делу;</p>
                <p>•	Сопровождение в подборе и обучении персонала;</p>
            </div>
            <div className="require_right">
                <p>•	Организация открытия и сопровождение бизнеса;</p>
                <p>•	Внедрение программы лояльности для гостей;</p>
                <p>•	Выгодные условия от поставщиков;</p>
                <p>•	Поддержка и продвижение в соц. сетях.</p>
            </div>
          </div>

          <h2 className="require_text">Цена франшизы LIMA:</h2>
          <div className="price_wrapper">
              <div className="price">
                  <div className="price_item">
                    <div className="price_text"></div>
                    <p>Эксклюзив на город от 100 тыс. до 200 тыс. человек</p>
                  </div>
                  
                  <div className="price_item">
                    <div className="price_text"></div>
                    <p>Эксклюзив на город (административный район города) от 200 тыс. до 500 тыс. человек</p>
                  </div>
                  
                  <div className="price_item">
                    <div className="price_text"></div>
                    <p>Эксклюзив на город от 500 тыс. человек</p>
                  </div>
                  
                  <div className="price_item">
                    <div className="price_text"></div>
                    <p>Эксклюзив на город за пределами Украины</p>
                  </div>
                  
                </div>
          </div>
          <h2 className="require_text">Свяжитесь с нами:</h2>
            <a href="mailto:partner@limahookah.com" className="mailto">partner@limahookah.com</a>
            <p></p>
      </div>
      <Footer/>
  </main>;
};

export default Partner;
