import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/header.scss'

const Header = () => {

   let [menuOpen, setMenuOpen] = useState(false)

   function closeMenu(e) {
      if(e.target.classList.contains('nav_wrapper'))
       {setMenuOpen(false)}
    }


  return <header className='header'>
     <NavLink to='/' className='logo'/>
     <div className={menuOpen?"nav_wrapper active":"nav_wrapper"} onClick={(e)=>{closeMenu(e)}}>
      <nav>
         <NavLink onClick={()=>setMenuOpen(false)} className={({ isActive }) => (isActive ? 'navlink active' : 'navlink')} to='/'>Головна</NavLink>
         <NavLink onClick={()=>setMenuOpen(false)}  to='/about'>Про нас</NavLink>
        
      </nav>
     </div>

        {/* Button */}
     <div className="nav_btn" onClick={()=>setMenuOpen(!menuOpen)}>
      <div className={menuOpen===true?"btn active" : "btn not-active"}>
         <span></span>
         <span></span>
         <span></span>
      </div>
     </div>

  </header>;
};

export default Header;
