import React from 'react';

const Missing = () => {
  return <main className='missing_wrapper'>
      <h1>404</h1>
      <h2>Страница не найдена</h2>
  </main>;
};

export default Missing;
