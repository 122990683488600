import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Header from '../components/Header';
import About from '../pages/About';
import Home from '../pages/Home';
import Missing from '../pages/Missing';
import Partner from '../pages/Partner';

const Layout = () => {
  return <>
    <Router>
        <Header/>
        <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/partner' element={<Partner/>}/>
        <Route path='*' element={<Missing/>}/>
        </Routes>
    </Router>
  </>;
};

export default Layout;
