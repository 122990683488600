import React from 'react';

const Footer = () => {

    let date = new Date();

  return <footer className='footer'>
      <p>© Lima Hookah {date.getFullYear()}</p>
  </footer>;
};

export default Footer;
