import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import '../styles/home.scss';

const Home = () => {
  // STATE
  let [info, setInfo] = useState(false);
  let [booking, setBooking] = useState(false);
  let [city, setCity] = useState(1);
  let [place, setPlace] = useState(0);

  //FUNCTIONS
  function showInfo() {
    setInfo(!info);
  }
  function closeInfo(e) {
    if (e.target.classList.contains('active')) {
      setInfo(!info);
    }
  }
  function showBooking() {
    setBooking(!booking);
  }
  function closeBooking(e) {
    if (e.target.classList.contains('booking_bg')) {
      setBooking(!booking);
    }
  }

  return (
    <main className="home_wrapper">
      <Helmet>
        <title>Lima Hookah</title>
        <meta
          name="keywords"
          content="кальян Київ, кальян Киев, кальян виноградарь, подол, теремки, покурити кальян, кальян Ірпінь, забронювати кальян, настольні ігри"
        />
        <link rel="canonical" href="/" />
        <meta
          name="description"
          content="Мережа профільних кальянних-чайних Lima Hookah переможець Hookah Battle Slavic Cup та “Кращий крафтовий кальян”. Широкий парк кальянів, величезний асортимент тютюну та напої із різних точок світу."
        />
      </Helmet>
      <div className="lima_text"></div>
      <h3 className="sub_header">Мережа профільних кальянних-чайних</h3>
      <p className="sub_header_info">
        Учасник та призер низки кальянних батлів України. Переможець Hookah
        Battle Slavic Cup або Битви Кальянщиків «Словʼянський Кубок» 2018.
      </p>
      <div className="services">
        <div className="service_item">
          <p>Кальян</p>
        </div>
        <div className="service_item">
          <p>Б/А Напої</p>
        </div>
        <div className="service_item">
          <p>Алкоголь</p>
        </div>
      </div>
      <div className="booking_info">
        <p>
          Резерв столика відбувається виключно у текстовому режимі в месенджерах
          Viber або Telegram (дзвінки недоступні)
        </p>
      </div>
      <div className="booking_wrapper">
        <div className="info_ico" onClick={showInfo} />
        <p>
          Важливо! Будь ласка, ознайомтесь із правилами відвідування наших
          закладів.
        </p>
        <div className="book_btn" onClick={showBooking}>
          Забронювати столик
        </div>
      </div>
      {/* INFO POPUP */}
      <div
        className={info ? 'info active' : 'info'}
        onClick={(e) => {
          closeInfo(e);
        }}
      >
        <div className="info_content">
          <div className="close" onClick={showInfo}></div>
          <h3>Правила відвідування</h3>
          <div className="rules">
            <p>
              ❗ У кальянній LIMA обслуговуються виключно особи які досягли
              18-ти років, тому усім в компанії з собою треба мати документ,
              який засвідчує повноліття.
            </p>
            <p>
              ❗ В нашій мережі закладів є настільні/карточні ігри, але в них
              заборонено грати на гроші.
            </p>
            <p>
              ❗ Будь ласка, не вживайте напої, принесені із собою. Ви можете
              приносити/замовляти їжу в деякі наші заклади, де немає своєї кухні
              (уточнюйте при бронюванні), але просимо забирати пакування з
              собою.
            </p>
            <p>
              ❗ Паління цигарок або будь-яких інших речовин у закладах
              заборонено.
            </p>
            <p>
              ❗ Ми тримаємо бронювання столу протягом 15-ти хвилин після
              початку, але якщо ви завчасно повідомити про запізнення - час
              очікування можна збільшити.
            </p>
            <p>
              ❗ Обовʼязкова умова обслуговування - замовлення кальяну. Напої
              можна брати навинос.
            </p>
            <p>
              ❗ Людям, що потрапляють до закладу у стані алкогольного або
              наркотичного спʼяніння, може бути відмовлено у обслуговуванні.
            </p>
            <p>
              ❗ Працівники мають право відмовити у обслуговуванні особам, що
              завдали матеріальної або моральної шкоди закладу, або не
              дотримувались вищезгаданих умов відвідування.
            </p>
          </div>
        </div>
      </div>

      {/* BOOKING POPUP */}
      <div
        className={booking ? 'booking_bg active' : 'booking_bg'}
        onClick={(e) => {
          closeBooking(e);
        }}
      >
        <div className="booking_content">
          <div className="booking_header">
            <h3>Забронювати столик:</h3>
            <div className="close" onClick={showBooking}></div>
          </div>
          <div className="booking_inner">
            <div className="booking_left">
              <h4>Оберіть місто:</h4>
              <div
                className={city === 1 ? 'city city_1 active' : 'city city_1'}
                onClick={() => {
                  setCity(1);
                  setPlace(0);
                }}
              >
                Київ
              </div>
              <div
                className={city === 2 ? 'city city_2 active' : 'city city_2'}
                onClick={() => {
                  setCity(2);
                  setPlace(0);
                }}
              >
                Ірпінь
              </div>
            </div>

            <div className="booking_right">
              {city === 1 ? (
                <div className="place">
                  <div className="place_item" onClick={() => setPlace(1)}>
                    <p>вул. Макіївська 7</p>
                    <span>
                      12.00-21.30 (слідкуйте за оновленням на мапі Google)
                    </span>
                  </div>
                  <div className="place_item" onClick={() => setPlace(2)}>
                    <p>вул. Олеся Гончара 36-Б</p>
                    <span>
                      12.00-21.30 (слідкуйте за оновленням на мапі Google)
                    </span>
                  </div>
                  <div className="place_item" onClick={() => setPlace(3)}>
                    <p>вул. Сержа Лифаря 20</p>
                    <span>
                      12.00-21.30 (слідкуйте за оновленням на мапі Google)
                    </span>
                  </div>
                  <div className="place_item" onClick={() => setPlace(4)}>
                    <p>вул. Лятошинського 18-Б</p>
                    <span>
                      12.00-21.30 (слідкуйте за оновленням на мапі Google)
                    </span>
                  </div>
                  <div className="place_item" onClick={() => setPlace(5)}>
                    <p>вул. Нижній Вал 19-21 Е</p>
                    <span>
                      12.00-21.30 (слідкуйте за оновленням на мапі Google)
                    </span>
                  </div>

                  {/* PLACES */}
                  <div
                    className={
                      place === 1 ? 'place_content active' : 'place_content'
                    }
                  >
                    <div className="back" onClick={() => setPlace(0)}></div>
                    <div className="place_bg place_bg_1">
                      <a href="https://g.page/lima-hookah-kiev" target="_blank">
                        вул.&nbsp;Макіївська&nbsp;7
                      </a>
                    </div>
                    <div className="place_scroll">
                      <p>Режим роботи:</p>
                      <h3>
                        <span>Пн - Нд:</span> 12.00-21.30 (слідкуйте за
                        оновленням на мапі Google)
                      </h3>
                      <div className="place_row">
                        <div className="place_row-item">
                          <p>Ми у соц мережах:</p>
                          <a
                            href="https://www.facebook.com/limahookahkiev/"
                            className="fb"
                          ></a>
                          <a
                            href="https://www.instagram.com/lima_hookah_makeevskaya/"
                            className="inst"
                          ></a>
                        </div>
                        <div className="place_row-item">
                          <p>Наше меню:</p>
                          <a
                            className="place_menu"
                            href="https://lima.postershop.me"
                            target="_blank"
                          ></a>
                        </div>
                      </div>
                      <p>Забронювати столик:</p>
                      <a
                        href="viber://add?number=380730896960"
                        className="viber"
                      >
                        Viber
                      </a>
                      <a
                        href="tg://resolve?domain=lima_makeevskaya"
                        className="telegram"
                      >
                        Telegram
                      </a>
                    </div>
                  </div>

                  <div
                    className={
                      place === 2 ? 'place_content active' : 'place_content'
                    }
                  >
                    <div className="back" onClick={() => setPlace(0)}></div>
                    <div className="place_bg place_bg_2">
                      <a
                        href="https://g.page/lima-hookah-gonchara"
                        target="_blank"
                      >
                        вул.&nbsp;Олеся&nbsp;Гончара&nbsp;36-Б
                      </a>
                    </div>
                    <div className="place_scroll">
                      <p>Режим роботи:</p>
                      <h3>
                        <span>Пн - Нд:</span> 12.00-21.30 (слідкуйте за
                        оновленням на мапі Google)
                      </h3>
                      <div className="place_row">
                        <div className="place_row-item">
                          <p>Ми у соц мережах:</p>
                          <a
                            href="https://www.facebook.com/lima.hookah.gonchara/"
                            className="fb"
                          ></a>
                          <a
                            href="https://www.instagram.com/lima_hookah_gonchara/"
                            className="inst"
                          ></a>
                        </div>
                        <div className="place_row-item">
                          <p>Наше меню:</p>
                          <a
                            className="place_menu"
                            href="https://limagonchara.ps.me/ "
                            target="_blank"
                          ></a>
                        </div>
                      </div>
                      <p>Забронювати столик:</p>
                      <a
                        href="viber://add?number=380636187736"
                        className="viber"
                      >
                        Viber
                      </a>
                      <a
                        href="tg://resolve?domain=limagonchara/"
                        className="telegram"
                      >
                        Telegram
                      </a>
                    </div>
                  </div>

                  <div
                    className={
                      place === 3 ? 'place_content active' : 'place_content'
                    }
                  >
                    <div className="back" onClick={() => setPlace(0)}></div>
                    <div className="place_bg place_bg_3">
                      <a
                        href="https://g.page/lima-hookah-troyeshchina"
                        target="_blank"
                        rel="noreferrer"
                      >
                        вул. Сержа Лифаря 20
                      </a>
                    </div>
                    <div className="place_scroll">
                      <p>Режим роботи:</p>
                      <h3>
                        <span>Пн - Нд:</span> 12.00-21.30 (слідкуйте за
                        оновленням на мапі Google)
                      </h3>
                      <div className="place_row">
                        <div className="place_row-item">
                          <p>Ми у соц мережах:</p>
                          <a
                            href="https://www.facebook.com/Lima.hookah.troyeshchina/"
                            className="fb"
                          ></a>
                          <a
                            href="https://www.instagram.com/lima_hookah_troyeshchina/"
                            className="inst"
                          ></a>
                        </div>
                        <div className="place_row-item">
                          <p>Наше меню:</p>
                          <a
                            className="place_menu"
                            href="https://limatroya.ps.me/ "
                            target="_blank"
                          ></a>
                        </div>
                      </div>

                      <p>Забронювати столик:</p>
                      <a
                        href="viber://add?number=380635518424"
                        className="viber"
                      >
                        Viber
                      </a>
                      <a
                        href="tg://resolve?domain=limasaburova"
                        className="telegram"
                      >
                        Telegram
                      </a>
                    </div>
                  </div>

                  <div
                    className={
                      place === 4 ? 'place_content active' : 'place_content'
                    }
                  >
                    <div className="back" onClick={() => setPlace(0)}></div>
                    <div className="place_bg place_bg_4">
                      <a
                        href="https://g.page/lima-hookah-teremki"
                        target="_blank"
                        rel="noreferrer"
                      >
                        вул. Лятошинського 18-Б
                      </a>
                    </div>
                    <div className="place_scroll">
                      <p>Режим роботи:</p>
                      <h3>
                        <span>Пн - Нд:</span> 12.00-21.30 (слідкуйте за
                        оновленням на мапі Google)
                      </h3>
                      <div className="place_row">
                        <div className="place_row-item">
                          <p>Ми у соц мережах:</p>
                          <a
                            href="https://www.facebook.com/limahookahteremki/"
                            className="fb"
                          ></a>
                          <a
                            href="https://www.instagram.com/lima_teremki/"
                            className="inst"
                          ></a>
                        </div>
                        <div className="place_row-item">
                          <p>Наше меню:</p>
                          <a
                            className="place_menu"
                            href="https://limateremki.ps.me"
                            target="_blank"
                          ></a>
                        </div>
                      </div>
                      <p>Забронировать столик:</p>
                      <a
                        href="viber://add?number=380635509604"
                        className="viber"
                      >
                        Viber
                      </a>
                      <a
                        href="tg://resolve?domain=limalyatoshinskogo"
                        className="telegram"
                      >
                        Telegram
                      </a>
                    </div>
                  </div>

                  <div
                    className={
                      place === 5 ? 'place_content active' : 'place_content'
                    }
                  >
                    <div className="back" onClick={() => setPlace(0)}></div>
                    <div className="place_bg place_bg_5">
                      <a href="https://g.page/limahookhpodol" target="_blank">
                        вул. Нижній Вал 19-21 Е
                      </a>
                    </div>
                    <div className="place_scroll">
                      <p>Режим роботи:</p>
                      <h3>
                        <span>Пн - Нд:</span> 12.00-21.30 (слідкуйте за
                        оновленням на мапі Google)
                      </h3>
                      <div className="place_row">
                        <div className="place_row-item">
                          <p>Ми у соц мережах:</p>
                          <a
                            href="https://www.facebook.com/limahookahpodol/"
                            className="fb"
                          ></a>
                          <a
                            href="https://www.instagram.com/lima_hookah_podol/"
                            className="inst"
                          ></a>
                        </div>
                        <div className="place_row-item">
                          <p>Наше меню:</p>
                          <a
                            className="place_menu"
                            href="https://limapodol.ps.me"
                            target="_blank"
                          ></a>
                        </div>
                      </div>
                      <p>Забронировать столик:</p>
                      <a
                        href="viber://add?number=380636445676"
                        className="viber"
                      >
                        Viber
                      </a>
                      <a
                        href="tg://resolve?domain=lima_hookah_podol"
                        className="telegram"
                      >
                        Telegram
                      </a>
                    </div>
                  </div>
                </div>
              ) : null}
              {city === 2 ? (
                <div className="place">
                  <div className="place_item" onClick={() => setPlace(6)}>
                    <p>вул. Грибоєдова 2</p>
                    <span>
                      12.00-21.30 (слідкуйте за оновленням на мапі Google)
                    </span>
                  </div>

                  <div
                    className={
                      place === 6 ? 'place_content active' : 'place_content'
                    }
                  >
                    <div className="back" onClick={() => setPlace(0)}></div>
                    <div className="place_bg place_bg_6">
                      <a
                        href="https://g.page/Limairpen"
                        target="_blank"
                        rel="noreferrer"
                      >
                        вул. Грибоєдова 2{' '}
                      </a>
                    </div>
                    <div className="place_scroll">
                      <p>Режим роботи:</p>
                      <h3>
                        <span>Пн - Нд:</span> 12.00-21.30 (слідкуйте за
                        оновленням на мапі Google)
                      </h3>
                      <div className="place_row">
                        <div className="place_row-item">
                          <p>Ми у соц мережах:</p>
                          <a
                            href="https://www.facebook.com/limahookahirpen/"
                            className="fb"
                          ></a>
                          <a
                            href="https://www.instagram.com/lima_hookah_irpen/"
                            className="inst"
                          ></a>
                        </div>
                        <div className="place_row-item">
                          <p>Наше меню:</p>
                          <a
                            className="place_menu"
                            href="https://limairpen.ps.me"
                            target="_blank"
                          ></a>
                        </div>
                      </div>
                      <p>Забронировать столик:</p>
                      <a
                        href="viber://add?number=380636444642"
                        className="viber"
                      >
                        Viber
                      </a>
                      <a
                        href="tg://resolve?domain=lima_hookah_irpen"
                        className="telegram"
                      >
                        Telegram
                      </a>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* <a target="_blank" href="/menu.pdf" className="menu"><span><i>Наше</i> меню</span> <div className="menu_ico"></div></a> */}
    </main>
  );
};

export default Home;
